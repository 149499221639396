// Demo.js
import { useState, useEffect } from "react";
import DialogModal from "./DialogModal";
import AnimatedDisplay from "./ModalDisplays/AnimatedDisplay";
import WebGLGameComponent from "../WebGLGameComponent";
import gamesConfig from "../GamesConfig";
import "../../styles/PresentScreen.css";
import headBanging from "../../assets/headbanging.gif";
import dj from "../../assets/dj_scratching.gif";
import counting from "../../assets/count_to_4.gif";
import groupOf4 from "../../assets/group_of_4.png";
import presentation from "../../assets/presentation.jpg";
import settings from "../../assets/settings.gif";
import gameplay from "../../assets/gameplay.gif";
import safeCracking from "../../assets/spy_cracking_safe.gif";
import workTogether from "../../assets/work_together.jpg";
import symbolFocus from "../../assets/symbol_focus.gif";
import decoderFocus from "../../assets/decoder_focus.gif";
import dawFocus from "../../assets/daw_focus.gif";
import answerFocus from "../../assets/draw_focus.gif";
import raiseHand from "../../assets/raise_hand.gif";
import tapScreen from "../../assets/tap_screen.gif";
import nextLevelFocus from "../../assets/next_level_focus.gif";
import chord from "../../assets/chord.gif";
import tablet from "../../assets/tablet.gif";
import excitedSmiley from "../../assets/excited_smiley.gif";
import blobOperaImage from "../../assets/blobopera.png";

const Demo = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [currGameIdx, setCurrGameIdx] = useState(0);
  const games = [
    {
      name: "Rhythm Kickoff",
      header: "Lesson 2: Introduction",
      props: {
        ChildComponent: AnimatedDisplay,
        childProps: {
          text: "Master RHYTHM and make your own beats",
          slides: [headBanging, dj],
        },
        open: true,
        onClose: () => {
          setCurrGameIdx((prevIdx) => prevIdx + 1);
        },
        classNames: "rounded-[100px]",
      },
    },
    {
      name: "Rhythm Rally",
      header: "Lesson 2: Game",
      props: {
        ChildComponent: AnimatedDisplay,
        childProps: {
          text: "Learn the basics of counting and creating rhythms in 4/4 time.",
          subText: [
            null,
            "Make groups of 4 or less.",
            "Assign each group a device or call one group to the front of the class at a time.",
            "Configure the options of the game.",
            "See who retains the rhythm the longest!",
          ],
          slides: [counting, groupOf4, presentation, settings, gameplay],
        },
        open: true,
        onClose: () => {
          // No action needed here
        },
        classNames: "rounded-[100px]",
      },
    },
    {
      name: "Beat Decoder",
      header: "Lesson 2: Write Music",
      props: {
        ChildComponent: AnimatedDisplay,
        childProps: {
          text: "You are a spy. Complete each mission by decoding and making the beats, breaking into the mansion and taking down the evil AI computer inside.",
          subText: [
            null,
            "Pair up! Choose one person to be the Decoder, and the other will be the Writer",
            "Decode the Rhythm: The Decoder looks at the rhythm symbols on the mission card, matches them with the squares and tells the writer what squares to fill in.",
            "Write the Rhythm: The Writer fills in the squares that match the rhythm symbols.",
            "Finished Decoding: Once you’ve decoded the rhythm, signal the teacher to access the DAW on the shared screen or device.",
            "Test Your Beat: The Writer inputs your decoded rhythm into GarageBand.",
            "Mission Complete: Once you enter the correct rhythm, you’ve completed the mission and move to the next level.",
            "Goal: Work together to successfully decode each rhythm and help defeat the evil AI computer!",
          ],
          slides: [
            safeCracking,
            workTogether,
            symbolFocus,
            decoderFocus,
            dawFocus,
            answerFocus,
            raiseHand,
            tapScreen,
            nextLevelFocus,
          ],
        },
        open: true,
        onClose: () => {
          // No action needed here
        },
        classNames: "rounded-[100px]",
      },
    },
    {
      name: "Blob Opera",
      header: "Lesson 2: To Be Continued",
      props: {
        ChildComponent: AnimatedDisplay,
        childProps: {
          text: "Get excited to dive into harmony next time",
          subText: [
            null,
            "Distribute devices to everyone and pair up if needed",
            "Navigate to Blob Opera and play!",
          ],
          slides: [chord, tablet, excitedSmiley],
        },
        open: true,
        onClose: () => {
          // No action needed here
        },
        classNames: "rounded-[100px]",
      },
    },
  ];

  const gameConfig = gamesConfig.RhythmRally;

  const currentGame = games[currGameIdx];

  // Use useEffect to open modal when currGameIdx changes
  useEffect(() => {
    if (currentGame.props && currentGame.props.open) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currGameIdx]);

  // Handle modal close
  const handleModalClose = () => {
    setIsOpen(false);
    if (currentGame.props.onClose) {
      currentGame.props.onClose();
    }
  };

  return (
    <div>
      <p className="m-auto text-white text-5xl font-black mt-4">
        {currentGame.header}
      </p>
      {/* Cycle lessons */}
      <div className="m-auto flex justify-center gap-4 my-4">
        <button
          className="bg-slate-400 text-white text-4xl font-bold w-24 h-full rounded-xl"
          onClick={() => {
            setCurrGameIdx((prevIdx) => Math.max(prevIdx - 1, 0));
          }}
          disabled={currGameIdx === 0}
        >
          Prev
        </button>
        <button
          className="bg-slate-400 text-white text-4xl font-bold w-24 h-full rounded-xl"
          onClick={() => {
            setCurrGameIdx((prevIdx) =>
              Math.min(prevIdx + 1, games.length - 1)
            );
          }}
          disabled={currGameIdx === games.length - 1}
        >
          Next
        </button>
      </div>
      {/* Reopen level instructions */}
      <div className="flex justify-center items-center border-slate-300 border-4 border-solid absolute right-12 top-24 w-12 h-12 rounded-3xl bg-slate-400 drop-shadow-md shadow-black shadow-md hover:scale-125 z-50">
        <button
          className="text-white text-4xl font-bold w-full h-full"
          onClick={() => setIsOpen(true)}
        >
          ?
        </button>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-full aspect-video relative overflow-hidden rounded-[15px] flex flex-col justify-center items-center">
          {currGameIdx === 1 && (
            <WebGLGameComponent
              gameName={"RhythmRally"}
              buildFolder={gameConfig.buildFolder}
              companyName={gameConfig.companyName}
              productVersion={gameConfig.productVersion}
            />
          )}
          {currGameIdx === 2 && (
            // Display the PDF using an iframe
            <iframe
              src="/RhythmDecoders.pdf"
              width="100%"
              height="600px"
              title="PDF Viewer"
              style={{ border: "none" }}
            ></iframe>
          )}
          {currGameIdx === 3 && (
            // Display the image and the "Go to Blob Opera" button
            <div className="flex flex-col items-center">
              <img
                src={blobOperaImage}
                alt="Blob Opera"
                className="mb-4 max-w-full h-auto"
              />
              <p className="text-white text-2xl mb-4">
                Ready to explore Blob Opera?
              </p>
              <a
                href="https://www.crazygames.com/game/blob-opera"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="bg-blue-500 text-white text-3xl font-bold py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-200"
                >
                  Go to Blob Opera
                </button>
              </a>
            </div>
          )}
        </div>
      </div>

      {/* Display the DialogModal if isOpen is true */}
      {currentGame.props && isOpen && (
        <DialogModal
          {...currentGame.props}
          open={isOpen}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default Demo;
