import Carousel from "../../Carousel";

const AnimatedDisplay = ({ text, subText, slides, close }) => {
  
  return (
    <div className="border-[50px] border-[#ff8ae2] min-h-[500px] w-[700px] flex flex-col items-center rounded-[76px] bg-[#FFEBCD] overflow-hidden">
      <div className="flex justify-center w-full mx-0 p-5 bg-[#a847e4] rounded">
        <p className="text-3xl font-bold text-white">{text}</p>
      </div>
      <Carousel slides={slides} subText={subText} close={close} />
    </div>
  );
};

export default AnimatedDisplay;
