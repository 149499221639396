import React from 'react';
import { Link } from 'react-router-dom'; // Use Link for navigation between routes
import logo from '../assets/logo.png';
import '../styles/NavBar.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <Link to="/"><img src={logo} className="navbar-logo" alt="logo" /></Link>
        <p className="navbar-text">(360)-391-3328</p>
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/tutorial">Tutorial</Link></li>
          <li><Link to="/lessons">Lessons</Link></li>
          <li><Link to="/games">Games</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
