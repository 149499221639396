import React from 'react';

const AudioPlayer = ({ src, title }) => {
  return (
    <div>
      <h2>{title}</h2>
      <audio controls>
        <source src={src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
