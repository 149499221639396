// PresentScreen.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import lessonPresentations from '../assets/presentation.json';
import '../styles/PresentScreen.css';
import WebGLGameComponent from '../components/WebGLGameComponent';
import GamesConfig from '../components/GamesConfig';
import AudioPlayer from '../components/AudioPlayer';

const sections = ['Introduction', 'Games', 'Applied Learning', 'To Be Continued'];

const CONTENT_TYPES = {
  VIDEO: 'video',
  LINK_IMAGE: 'linkImage',
  UNITY: 'unity',
};

const PresentScreen = () => {
  const { id, sectionName } = useParams();
  const { state } = useLocation();
  const lessonTitle = state?.lessonTitle || 'Default Lesson Title';
  const navigate = useNavigate();

  const [lesson, setLesson] = useState(null);
  const [presentationData, setPresentationData] = useState({});
  const [currentGameIndex, setCurrentGameIndex] = useState(0);

  const currentSectionIndex = sections.indexOf(sectionName);
  const isFirstSection = currentSectionIndex === 0;
  const isLastSection = currentSectionIndex === sections.length - 1;

  const [currentSubsection, setCurrentSubsection] = useState(null);

  // Fetch lessonPresentation data based on the lessonId and sectionName from the local JSON
  useEffect(() => {
    const fetchPresentationData = async () => {
      try {
        const lessonData = lessonPresentations.lessonPresentations.find(
          (lesson) => lesson.lessonId === id
        );

        if (lessonData) {
          setLesson(lessonData);

          const section = lessonData.sections?.find(
            (sec) => sec.sectionName === sectionName
          );
          setPresentationData(section || {});

          const hasSubsections =
            section.subsections && section.subsections.length > 0;

          if (section && hasSubsections) {
            setCurrentSubsection(section.subsections[0]);
            setCurrentGameIndex(0);
          }
        } else {
          console.error('Lesson not found');
        }
      } catch (error) {
        console.error('Error fetching presentation data:', error);
      }
    };

    fetchPresentationData();
  }, [id, sectionName]);

  const hasSubsections =
    presentationData.subsections && presentationData.subsections.length > 0;

  // Navigate to the next subsection within the section
  const handleNextSubsection = () => {
    if (
      presentationData.subsections &&
      currentGameIndex < presentationData.subsections.length - 1
    ) {
      const nextSubsection = presentationData.subsections[currentGameIndex + 1];
      setCurrentSubsection(nextSubsection);
      setCurrentGameIndex(currentGameIndex + 1);
    } else {
      handleNext(); // Move to the next section when subsections are done
    }
  };

  // Navigate to the previous subsection within the section
  const handlePreviousSubsection = () => {
    if (currentGameIndex > 0) {
      const previousSubsection = presentationData.subsections[currentGameIndex - 1];
      setCurrentSubsection(previousSubsection);
      setCurrentGameIndex(currentGameIndex - 1);
    } else {
      handleBack(); // Move back when at the first subsection
    }
  };

  const handleNext = () => {
    if (!isLastSection) {
      const nextSection = sections[currentSectionIndex + 1];
      navigate(`/lessons/${id}/present/${nextSection}`, { state: { lessonTitle } });
    } else {
      // Navigate back to /lessons when at the last section
      navigate('/lessons');
    }
  };

  const handleBack = () => {
    if (isFirstSection && currentGameIndex === 0) {
      navigate(`/lessons/${id}`, { state: { lessonTitle } });
    } else if (hasSubsections && currentGameIndex > 0) {
      const previousSubsection = presentationData.subsections[currentGameIndex - 1];
      setCurrentSubsection(previousSubsection);
      setCurrentGameIndex(currentGameIndex - 1);
    } else {
      const previousSection = sections[currentSectionIndex - 1];
      navigate(`/lessons/${id}/present/${previousSection}`, { state: { lessonTitle } });
    }
  };

  if (!lesson || !presentationData || (hasSubsections && !currentSubsection)) {
    return <div>Loading...</div>;
  }

  // Content rendering function
  const renderContent = () => {
    const data = hasSubsections ? currentSubsection : presentationData;

    switch (data.contentType) {
      case CONTENT_TYPES.VIDEO:
        return (
          <div className="media-container">
            <iframe
              className="present-video"
              src={data.presentationUrl}
              title="Video Content"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        );

      case CONTENT_TYPES.LINK_IMAGE:
        return (
          <div className="media-container">
            <a href={data.linkUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={data.imageUrl}
                alt="Linked Content"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </a>
          </div>
        );

      case CONTENT_TYPES.UNITY:
        const gameName = data.gameName;
        const gameConfig = GamesConfig[gameName];

        if (!gameConfig) {
          return <div>Game "{gameName}" not found in gamesConfig.</div>;
        }

        return (
          <div className="unity-game-container">
            <WebGLGameComponent
              gameName={gameName}
              buildFolder={gameConfig.buildFolder}
              companyName={gameConfig.companyName}
              productVersion={gameConfig.productVersion}
            />
          </div>
        );

      default:
        return <div>Content type not supported.</div>;
    }
  };

  // Determine the current data source
  const data = hasSubsections ? currentSubsection : presentationData;

  return (
    <div className="present-screen">
      <h1>{lessonTitle}</h1>

      {hasSubsections ? (
        <>
          <h2>
            {currentSubsection?.title} ({currentSubsection?.time})
          </h2>
          <h3>Purpose: {currentSubsection?.purpose}</h3>
        </>
      ) : (
        <>
          <h2>
            {presentationData.sectionName} ({presentationData.time})
          </h2>
          <h3>Purpose: {presentationData?.purpose}</h3>
        </>
      )}

      <div className="present-content">
        {/* Content container on the left */}
        <div className="content-container">
          {renderContent()}
        </div>

        {/* Instructions container on the right */}
        <div className="instructions-container">
          {sectionName === 'Introduction' ? (
            <>
              <h3>Agenda</h3>
              {lesson.sections.map((section, index) => (
                <div key={index} className="instruction-section">
                  <h4 className="section-header">{section.sectionName}</h4>
                  {section.subsections ? (
                    <ul className="activities-list">
                      {section.subsections.map((subsection, idx) => (
                        <li key={idx} className="activity-item">
                          {subsection.title} ({subsection.time})
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              ))}
            </>
          ) : (
            <>
              <h3>Instructions</h3>
              <ul>
                {data.instructions?.map((instruction, index) => (
                  <li key={index}>{instruction}</li>
                ))}
              </ul>

              {/* Check for audio files and render them */}
              {data.audioFiles && data.audioFiles.length > 0 && (
                <>
                  {data.audioFiles.map((audioFile, index) => (
                    <AudioPlayer
                      key={index}
                      src={`/${audioFile.fileName}`}
                      title={audioFile.title}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="present-controls">
        <button className="present-button" onClick={handlePreviousSubsection}>
          {isFirstSection && currentGameIndex === 0 ? 'Back to Lesson Plan' : 'Back'}
        </button>
        {hasSubsections && currentGameIndex < presentationData.subsections.length - 1 ? (
          <button className="present-button" onClick={handleNextSubsection}>
            Next
          </button>
        ) : (
          <button className="present-button" onClick={handleNext}>
            {isLastSection ? 'Finish' : 'Next Section'}
          </button>
        )}
      </div>
    </div>
  );
};

export default PresentScreen;
