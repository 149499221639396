// HomeScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HomeScreen.css'; // Import the CSS file
import welcomeImage from '../assets/welcomeImage.png'; // Import the image

const HomeScreen = () => {
  const navigate = useNavigate();

  const handlePlayButtonClick = () => {
    console.log('Start clicked!');
    navigate('/lessons');
  };

  return (
    <div className="home-container">
      <main className="main-content">
        {/* Title */}
        <h1 className="welcome-title">Welcome!</h1>

        {/* Flex container for text and image */}
        <div className="text-image-container">
          {/* Left side - Text Section */}
          <div className="text-section">
            <section>
              <h2 className="section-title">What is Overture Games?</h2>
              <ol className="custom-numbered-list">
                <li>Engaging STEM & Music Curriculum</li>
                <li>Plug & Play built by Northwestern University Experts</li>
                <li>Easy 24/7 Support</li>
              </ol>
            </section>

            <section>
              <h2 className="section-title">How to Get Started</h2>
              <ol className="custom-numbered-list">
                <li>Click Start</li>
                <li>Do the 5 minute training</li>
                <li>Run the material & have fun!</li>
              </ol>
            </section>
          </div>

          {/* Right side - Image */}
          <div className="image-section">
            <img src={welcomeImage} alt="Welcome" className="welcome-image" />
          </div>
        </div>

        {/* Start link - Positioned at the bottom right */}
        <div className="start-link-container">
          <div className="start-link" onClick={handlePlayButtonClick}>
            Start →
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomeScreen;
