import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/TutorialScreen.css';

const TutorialScreen = () => {
  const navigate = useNavigate();

  const curriculumParts = [
    { 
      title: "Introduction", 
      description: "Give an overview & Get students comfortable",
      duration: "5-10 min",
      videoUrl: "https://www.youtube.com/embed/CL0XXQQch0w?si=CbKBzAR098JJ1Auy" 
    },
    { 
      title: "Games", 
      description: "Engage, Teach & Build Community",
      duration: "15-30 min",
      videoUrl: "https://www.youtube.com/embed/B4kDz_PM7k4?si=jy7LL0phOFyAYMWT" 
    },
    { 
      title: "Applied Learning", 
      description: "Work Towards Final Showcase (Write Music)",
      duration: "15-20 min",
      videoUrl: "https://www.youtube.com/embed/L_pEJ0cXApU?si=gwe5MDb7BGiyfDbk" 
    },
    { 
      title: "To Be Continued...", 
      description: "Ignite Excitement for the Next Time",
      duration: "",
      videoUrl: "https://www.youtube.com/embed/xSr3f06XgsU?si=cqViax50vVdmZxOn" 
    }
  ];

  return (
    <div className="tutorial-screen">
      <div className="tutorial-card">
        <main>
          <h2 className="tutorial-h2"> How to Teach the Overture Program</h2>
          <p className="tutorial-intro"> Our goal? Save you time and make it super easy! Just 2 minutes and you will be ready to teach :)
            </p>
            <p className="tutorial-intro"> Every lesson is composed of 4 parts</p>

          <div className="tutorial-parts">
            {curriculumParts.map((part, index) => (
              <div key={index} className="tutorial-part">
                {/* <div className="tutorial-video-container">
                  <iframe 
                    className="tutorial-video" 
                    width="100%" 
                    height="100px" 
                    src= {part.videoUrl}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                  </iframe>
                </div> */}
                <div className="part-details">
                  <h3>{index + 1}. {part.title}</h3>
                  <p>{part.description}</p>
                  {part.duration && <p className="duration">{part.duration}</p>}
                </div>
              </div>
            ))}
          </div>
          <p className="tutorial-intro"> Each module has a lesson it trains you to teach!</p>
        </main>

        {/* Centered button at the bottom */}
        <div className="to-lessons-container">
          <button 
            onClick={() => navigate('/lessons')} 
            className="to-lessons-button"
          >
            To Lessons
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutorialScreen;
