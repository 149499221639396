import { useEffect, useRef, useMemo, useCallback } from "react";

const DialogModal = ({
  ChildComponent,
  childProps,
  open,
  onClose,
  classNames,
}) => {
  const dialogModalRef = useRef(null);

  const openModal = () => {
    dialogModalRef.current?.showModal();
  };

  const closeModal = useCallback(() => {
    dialogModalRef.current?.close();
    onClose && onClose();
  }, [onClose]);

  // load dynamic diplays into the modal
  const childComponentProps = useMemo(
    () => ({
      ...childProps,
      close: closeModal,
    }),
    [childProps, closeModal]
  );

  useEffect(() => {
    open ? openModal() : closeModal();
  }, [open, closeModal]);

  useEffect(() => {
    // allow keyboard interactions to close the modal
    const closeIfEscaped = (e) => {
      if (e.key === "Escape") closeModal();
    };

    window.addEventListener("keydown", closeIfEscaped);

    return () => {
      window.removeEventListener("keydown", closeIfEscaped);
    };
  }, [closeModal]);

  return (
    <dialog
      ref={dialogModalRef}
      className={`backdrop:bg-black/55 ${classNames}`}
      onClose={onClose}
    >
      <ChildComponent {...childComponentProps} />
    </dialog>
  );
};

export default DialogModal;
