import React from 'react';
import { Link } from 'react-router-dom';
import gamesConfig from '../components/GamesConfig';
import '../styles/GamesDisplayScreen.css';

const GamesDisplayScreen = () => {
  const games = Object.keys(gamesConfig).map((gameName) => ({
    name: gameName,
    ...gamesConfig[gameName],
  }));

  return (
    <div className="games-display-screen">
      <h1>Our Games</h1>
      <div className="games-grid">
        {games.map((game) => (
          <div className="game-card" key={game.name}>
            <Link to={`/game/${encodeURIComponent(game.name)}`} className="game-link">
              <div className="game-image-container">
                <img src={game.image} alt={game.name} className="game-image" />
              </div>
              <div className="game-details">
                <h2>{game.displayTitle}</h2>
                <p>{game.description}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GamesDisplayScreen;
