import { useMemo, useState } from "react";

const Carousel = ({ slides, subText, close }) => {
  const [currSlideIdx, setCurrSlideIdx] = useState(0);

  const hasSubText = useMemo(
    () => Boolean(subText?.[currSlideIdx]),
    [subText, currSlideIdx]
  );

  const isLastSlide = useMemo(
    () => currSlideIdx === slides.length - 1,
    [currSlideIdx, slides.length]
  );

  const selectNextSlide = () => {
    setCurrSlideIdx((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const selectPrevSlide = () => {
    setCurrSlideIdx(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  const selectSlideByIndex = (idx) => {
    setCurrSlideIdx(idx);
  };

  const handleNextButtonClick = () => {
    if (!isLastSlide) {
      selectNextSlide();
    } else {
      close();
      setCurrSlideIdx(0);
    }
  };

  return (
    <div className="rounded-[50px] w-full">
      {/* slide container */}
      <div
        className="flex justify-start transition-transform duration-[0.5s] ease-[ease-in-out]"
        style={{ transform: `translateX(-${currSlideIdx * 100}%)` }}
      >
        {slides.map((slide, i) => (
          <div
            className="min-w-full flex flex-col justify-center items-center"
            key={`carousel-slide-${i}`}
          >
            {subText?.[currSlideIdx] && (
              <p className="text-[#a847e4] max-w-[700px] font-bold px-3 text-center my-3">
                {subText[currSlideIdx]}
              </p>
            )}
            <div
              className={`flex h-64 w-full bg-center bg-no-repeat bg-contain transition-transform duration-[0.5s] ease-[ease-in-out] ${
                hasSubText ? "" : "mt-3"
              }`}
              style={{ backgroundImage: `url(${slide})` }}
            ></div>
          </div>
        ))}
      </div>

      {/* button to travel to a specific slide */}
      <div className="flex justify-center gap-6 my-3">
        {slides.map((_, i) => (
          <button
            key={`carousel-dot-${i}`}
            className={`w-4 h-4 cursor-pointer transition-[background-color] duration-[0.3s] rounded-[50%] border-none drop-shadow-2xl ${
              i === currSlideIdx
                ? "bg-[#b642f5] scale-125"
                : "bg-[#ff8ae2] hover:bg-[#6e44ff]"
            }`}
            onClick={() => selectSlideByIndex(i)}
          />
        ))}
      </div>
      {/* Previous and Next buttons */}
      <button
        onClick={selectPrevSlide}
        className="absolute -translate-y-2/4 bg-[rgba(0,0,0,0.5)] text-white text-2xl cursor-pointer z-10 p-2.5 border-none left-2 top-2/4 h-24 w-8 rounded-2xl"
        disabled={currSlideIdx === 0}
      >
        ‹
      </button>
      <button
        onClick={selectNextSlide}
        className="absolute -translate-y-2/4 bg-[rgba(0,0,0,0.5)] text-white text-2xl cursor-pointer z-10 p-2.5 border-none right-2 top-2/4 h-24 w-8 rounded-2xl"
        disabled={isLastSlide}
      >
        ›
      </button>
      {/* Next or Continue button */}
      <button
        className="hover:shadow-2xl hover:scale-110 rounded-xl py-1 w-36 bg-[#6e44ff] absolute bottom-1 right-[110px] text-center text-white font-black text-2xl"
        onClick={handleNextButtonClick}
      >
        {isLastSlide ? "CONTINUE" : "NEXT"}
      </button>
    </div>
  );
};

export default Carousel;

