import React, { useEffect } from 'react';

const WebGLGameComponent = ({ gameName, buildFolder, companyName, productVersion }) => {
  useEffect(() => {
    // Element references
    const container = document.getElementById('unity-container');
    const canvas = document.getElementById('unity-canvas');
    const loadingBar = document.getElementById('unity-loading-bar');
    const progressBarFull = document.getElementById('unity-progress-bar-full');
    const fullscreenButton = document.getElementById('unity-fullscreen-button');
    const warningBanner = document.getElementById('unity-warning');

    // Show banner function
    function unityShowBanner(msg, type) {
      function updateBannerVisibility() {
        warningBanner.style.display = warningBanner.children.length ? 'block' : 'none';
      }
      const div = document.createElement('div');
      div.innerHTML = msg;
      warningBanner.appendChild(div);
      if (type === 'error') div.style = 'background: red; padding: 10px;';
      else {
        if (type === 'warning') div.style = 'background: yellow; padding: 10px;';
        setTimeout(() => {
          warningBanner.removeChild(div);
          updateBannerVisibility();
        }, 5000);
      }
      updateBannerVisibility();
    }

    // Replace spaces with underscores for filenames
    const formattedGameName = gameName.replace(/\s+/g, '_');

    // Build configuration
    const buildUrl = `${process.env.PUBLIC_URL}/${buildFolder}`;
    const loaderUrl = `${buildUrl}/${formattedGameName}.loader.js`;
    
    const config = {
      dataUrl: `${buildUrl}/${formattedGameName}.data`,
      frameworkUrl: `${buildUrl}/${formattedGameName}.framework.js`,
      codeUrl: `${buildUrl}/${formattedGameName}.wasm`,
      streamingAssetsUrl: 'StreamingAssets',
      companyName: companyName || 'Default Company',
      productName: gameName,
      productVersion: productVersion || '1.0',
      showBanner: unityShowBanner,
    };

    // Device check
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      // Mobile device adjustments
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content =
        'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';
      document.getElementsByTagName('head')[0].appendChild(meta);
      container.className = 'unity-mobile';
      canvas.className = 'unity-mobile';
      // Optionally adjust devicePixelRatio
      // config.devicePixelRatio = 1;
      unityShowBanner('WebGL builds are not supported on mobile devices.');
    } else {
      // Desktop adjustments
      canvas.style.width = '960px';
      canvas.style.height = '600px';
    }

    loadingBar.style.display = 'block';

    // Load the Unity loader script
    const script = document.createElement('script');
    script.src = loaderUrl;
    script.onload = () => {
      window.createUnityInstance(canvas, config, (progress) => {
        progressBarFull.style.width = 100 * progress + '%';
      })
        .then((unityInstance) => {
          loadingBar.style.display = 'none';
          fullscreenButton.onclick = () => {
            unityInstance.SetFullscreen(1);
          };
          // Store the unityInstance for cleanup
          canvas.unityInstance = unityInstance;
        })
        .catch((message) => {
          alert(message);
        });
    };
    script.onerror = () => {
      console.error('Failed to load Unity loader script.');
    };
    document.body.appendChild(script);

    // Cleanup on unmount
    return () => {
      if (canvas.unityInstance) {
        canvas.unityInstance.Quit().then(() => {
          console.log('Unity instance unloaded.');
        });
      }
      // Remove the script
      document.body.removeChild(script);
    };
  }, [gameName, buildFolder, companyName, productVersion]);

  return (
    <div id="unity-container">
      <canvas id="unity-canvas" width="960" height="600"></canvas>
      <div id="unity-loading-bar">
        <div id="unity-logo"></div>
        <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full"></div>
        </div>
      </div>
      <div id="unity-warning"></div>
      <div id="unity-footer">
        <div id="unity-webgl-logo"></div>
        <div id="unity-fullscreen-button"></div>
        <div id="unity-build-title">{gameName}</div>
      </div>
    </div>
  );
};

export default WebGLGameComponent;

