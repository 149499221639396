import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '../styles/LessonPlanScreen.css';
import lessonScreenData from '../assets/lessonScreenData.json'; // Assuming JSON is in assets folder

// Import Material-UI components
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LessonPlanScreen = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);

  // State to manage which accordion is expanded
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    // Find the lesson with the matching ID from the hardcoded JSON data
    const lessonData = lessonScreenData.lessonScreenData.find(lesson => lesson._id === id);
    if (lessonData) {
      setLesson(lessonData);

      // Initialize the expanded state to the first outcome's index
      if (lessonData.lessonOutcomes && lessonData.lessonOutcomes.length > 0) {
        setExpanded(`panel0`);
      }
    } else {
      console.error('Lesson not found');
    }
  }, [id]);

  if (!lesson) {
    return <div>Loading...</div>;
  }

  const lessonNumber = location.state?.lessonNumber || 1;
  const handlePresentClick = () => {
    navigate(`/lessons/${id}/present/Introduction`, { state: { lessonTitle: `Lesson ${lessonNumber}: ${lesson.title}` } });
  };

  // Handle accordion expansion
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="lesson-plan">
      <h1>Lesson {lessonNumber}: {lesson.title}</h1> {/* Updated Title */}
      <h2>Lesson Plan</h2>
      <div className="lesson-content">
        <section>
          <h2>Lesson Outcomes</h2>
          {lesson.lessonOutcomes.map((outcome, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography>{outcome.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{outcome.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </section>

        <section>
          <h2>Learning Objectives</h2>
          <ul>
            {lesson.learningObjectives.map((objective, index) => (
              <li key={index}>{objective}</li>
            ))}
          </ul>
        </section>

        <section>
          <h2>Materials</h2>
          <ul>
            {lesson.materials.map((material, index) => (
              <li key={index}>{material}</li>
            ))}
          </ul>
        </section>

        <section>
          <h2>Showcase Deliverable</h2>
          <p>{lesson.showcaseDeliverable.description}</p>
          <p>{lesson.showcaseDeliverable.exportedAudioFile}</p>
        </section>

      </div>

      {/* Add the Present button */}
      <div className="present-button-container">
        <button className="present-button-lessonplan" onClick={handlePresentClick}>
          Next: Agenda
        </button>
      </div>
    </div>
  );
};

export default LessonPlanScreen;
