// Place this file in your config directory or suitable location
const gamesConfig = {
  'BeatDecoders': {
    buildFolder: 'WebGLBuilds/BeatDecoders/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/RhythmDecoders.png",
    description: "Learn to make a beat from rhythms.",
    displayTitle: "Beat Decoders"
  },
    "GravityGame": {
    buildFolder: "WebGLBuilds/GravityGame/Build",
    companyName: "Overture Games",
    productVersion: "1.1",
    image: "/images/gravity_game.png",
    description: "A fun brain break!",
    displayTitle: "Gravity Game"
  },
  "InstrumentGuesser1": {
    buildFolder: "WebGLBuilds/InstrumentGuesser1/Build",
    companyName: "Overture Games",
    productVersion: "1.0",
    image: "/images/instrument_guesser.png",
    description: "Work together to guess instruments by listening.",
    displayTitle: "Guess the Instrument!"
  },
  'HarmonyGuesser': {
    buildFolder: 'WebGLBuilds/HarmonyGuesser/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/harmony_guesser.png",
    description: "Learn the difference between major and minor chords.",
    displayTitle: "Harmony Guesser: Major or Minor?"
  },
  'RhythmRally': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/rhythm_rally.png",
    description: "Compete and improve your rhythm memory.",
    displayTitle: "Rhythm Rally"
  }

  };
  
  export default gamesConfig;